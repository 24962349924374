<template>
  <div>
    <CommonIndex
      :pageType="pageType"
      :showThreshold="true"
      @showDetail="showDetail"
    />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import CommonIndex from '@/views/safety-device/component/common-index';
import { createDrawer } from '@triascloud/x-components';
import WaterMeterDetail from './water-meter-detail';
import { pageTypeEnum } from '@/views/safety-device/component/safety-utils';

@Component({
  components: {
    CommonIndex,
  },
})
export default class WaterMeter extends Vue {
  // 智能水表
  pageType = pageTypeEnum.waterMonitor;

  modalDrawer = null;
  handleCloseModalDrawer() {
    this.modalDrawer.handleClosed();
  }
  showDetail(record) {
    this.modalDrawer = createDrawer(
      () => (
        <WaterMeterDetail
          detailData={record}
          pageType={this.pageType}
          close={this.handleCloseModalDrawer}
        />
      ),
      {
        title: this.$t('safety.deviceDetail'),
        width: '720px',
        wrapClassName: 'deviceDetail',
      },
    );
  }
}
</script>

<style lang="less" module>
@import '@/views/safety-device/component/safety.less';
</style>
